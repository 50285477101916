import Vue from 'vue';
import axios from "@/axios";
import tinycolor from "tinycolor2";

/* 
  Color sets:
    - tenant_config - colors and other settings from the server.
    - defaultColors - hardcoded default colors.
    - colors - applied on the frontend.
*/

const focusColors = ['primary', 'secondary', 'accent', 'neutral'];

/*
  To add a new variation to color add a new color to:
  - state.colors
  - its default value to shared/assets/styles/style.scss
  - new color to tailwind.config.js theme.extend.colors
*/
const colorVariations = [
  {
    postfix: '100',
    make: (color) => tinycolor(color).lighten(45).toHex8String(),
  },
  {
    postfix: '200',
    make: (color) => tinycolor(color).lighten(25).toHex8String(),
  },
  {
    postfix: '300',
    make: (color) => tinycolor(color).lighten(12).toHex8String(),
  },
  {
    postfix: '400',
    make: (color) => tinycolor(color).lighten(3).toHex8String(),
  },
  {
    postfix: '500',
    make: (color) => tinycolor(color).darken(2).toHex8String(),
  },
  {
    postfix: '600',
    make: (color) => tinycolor(color).darken(5).toHex8String(),
  },
  {
    postfix: '700',
    make: (color) => tinycolor(color).darken(9).toHex8String(),
  },
  {
    postfix: '800',
    make: (color) => tinycolor(color).darken(12).toHex8String(),
  },
]

export default {
  state: {
    // settings from the server
    tenant_config: {},
    // current frontend settings:
    theme: {
      defaultColors: {
        'main_color': { // primary
          var: '--theme-color-main',
          value: '#0D69D5'
        },
        'table_header_footer': {
          var: '--theme-color-thead',
          value: '#029ADB'
        },
        'section_selection': {
          var: '--theme-color-selection',
          value: '#029ADB'
        },
        'page_background': {
          var: '--theme-color-page-bg',
          value: '#EDF2F7'
        },
        'text_menu_icon': {
          var: '--theme-color-text',
          value: '#282828'
        },
        'line_sepration': {
          var: '--theme-color-line',
          value: '#DDDDDD'
        },
        'box_stroke_color': {
          var: '--theme-color-box-stroke',
          value: '#DDDDDD'
        },
        'white_text': {
          var: '--theme-color-white-text',
          value: '#FFFFFF'
        },
      },
      // temporary frontend colors:
      colors: {
        'main_color': { // primary
          name: 'Main Color',
          var: '--theme-color-main',
          daisyui: ['primary', 'accent'],
          variations: 'primary',
          // value: '#0D69D5'
        },
        'accent': {
          var: '--a',
        },
        'accent-focus': {
          var: '--af',
        },
        'primary': {
          var: '--p',
        },
        'primary-focus': {
          var: '--pf',
        },
        'primary-100': {
          var: '--theme-color-primary-100',
        },
        'primary-200': {
          var: '--theme-color-primary-200',
        },
        'primary-300': {
          var: '--theme-color-primary-300',
        },
        'primary-600': {
          var: '--theme-color-primary-600',
        },
        'opacity': {
          var: '--tw-bg-opacity'
        },
        'table_header_footer': {
          name: 'Table Header/Footer',
          var: '--theme-color-thead',
        },
        'section_selection': {
          name: 'Section Selection',
          var: '--theme-color-selection',
        },
        'page_background': {
          name: 'Page Background',
          var: '--theme-color-page-bg',
        },
        'text_menu_icon': {
          name: 'Text & Menu Icon',
          var: '--theme-color-text',
          daisyui: ['base-content'],
          variations: 'base-content',
        },
        'base-content': {
          var: '--bc',
        },
        'base-content-300': {
          var: '--theme-color-text-300',
        },
        'base-content-600': {
          var: '--theme-color-text-600',
        },
        'base-content-800': {
          var: '--theme-color-text-800',
        },
        'line_sepration': {
          name: 'Line Separation',
          var: '--theme-color-line',
        },
        'box_stroke_color': {
          name: 'Box Stroke Color',
          var: '--theme-color-box-stroke',
        },
        'white_text': {
          name: 'White Text',
          var: '--theme-color-white-text',
          daisyui: ['primary-content', 'secondary-content', 'accent-content', 'neutral-content'],
          variations: 'white_text',
        },
        'white_text-300': {
          var: '--theme-color-white-text-300',
        },
        'white_text-600': {
          var: '--theme-color-white-text-600',
        },
        'primary-content': {
          var: '--pc',
        },
        'secondary-content': {
          var: '--sc',
        },
        'accent-content': {
          var: '--ac',
        },
        'neutral-content': {
          var: '--nc',
        },
      },
      logo: '',
      use_default: false,
    }
  },

  getters: {
    getTenantConfig: (state) => state.tenant_config,
    getThemeColors: (state) => state.theme.colors,
    getDefaultColors: (state) => state.theme.defaultColors,
    getThemeLogo: (state) => state.theme.logo,
    getThemeUseDefault: (state) => state.theme.use_default,
  },

  mutations: {
    SET_TENANT_CONFIG(state, payload) {
      // set theme only if reset_flag == false:
      Vue.set(state, 'tenant_config', payload);
      Vue.set(state.theme, 'use_default', payload.reset_flag);
      if (payload.reset_flag) return;
      // parse colors from server:
      const serverColors = state.tenant_config.uiconfig.color_config;
      const colors = state.theme.colors;
      Object.entries(serverColors).forEach(([key, value]) => {
        if (!value) return;
        Vue.set(colors[key], 'value', value);
        const dColors = colors[key].daisyui;
        // daisyui variables resetting:
        if (dColors) {
          const { h, s, l } = tinycolor(value).toHsl();
          const daisyuiColor = `${h} ${Math.round(s*100)}% ${Math.round(l*100)}%`;
          const { h: hf, s: sf, l: lf } = tinycolor(value).lighten(8).toHsl();
          const daisyuiFocusColor = `${hf} ${Math.round(sf*100)}% ${Math.round(lf*100)}%`;

          dColors.forEach((dColor) => {
            Vue.set(colors[dColor], 'value', daisyuiColor);
            // new focus color:
            if (focusColors.includes(dColor)) {
              Vue.set(colors[`${dColor}-focus`], 'value', daisyuiFocusColor);
            }
          });
        }
        const variations = colors[key].variations;
        // color variations:
        if (variations) {
          colorVariations.forEach(variant => {
            const vName = `${variations}-${variant.postfix}`;
            if (colors[vName]) {
              Vue.set(colors[vName], 'value', variant.make(value));
            }
          })
        }
      });
      // set logo from server:
      Vue.set(state.theme, 'logo', payload.logo_url);
    },
    // SET_THEME_COLORS(state, payload) {
    //   const colors = state.theme.colors;
    //   Object.entries(payload).forEach(([key, color]) => {
    //     if (!color.value || !colors[key]) return;
    //     Vue.set(colors[key], 'value', color.value);
    //     const dColor = colors[key].daisyui;
    //     // daisyui variables resetting:
    //     if (dColor) {
    //       const { h, s, l } = tinycolor(color.value).toHsl();
    //       Vue.set(colors[dColor], 'value', `${h} ${Math.round(s*100)}% ${Math.round(l*100)}%`);
    //       // new focus color:
    //       if (focusColors.includes(dColor)) {
    //         const { h: hf, s: sf, l: lf } = tinycolor(color.value).lighten().toHsl();
    //         Vue.set(colors[`${dColor}-focus`],
    //         'value',
    //         `${hf} ${Math.round(sf*100)}% ${Math.round(lf*100)}%`
    //         );
    //       }
    //     }
    //   })
    // },
    SET_THEME_COLOR(state, {color, value}) {
      const colors = state.theme.colors;
      Vue.set(colors[color], 'value', value);
      const dColors = colors[color].daisyui;
      // daisyui variables resetting:
      if (dColors) {
        const { h, s, l } = tinycolor(value).toHsl();
        const daisyuiColor = `${h} ${Math.round(s*100)}% ${Math.round(l*100)}%`;
        const { h: hf, s: sf, l: lf } = tinycolor(value).lighten(8).toHsl();
        const daisyuiFocusColor = `${hf} ${Math.round(sf*100)}% ${Math.round(lf*100)}%`;

        dColors.forEach((dColor) => {
          Vue.set(colors[dColor], 'value', daisyuiColor);
          // new focus color:
          if (focusColors.includes(dColor)) {
            Vue.set(colors[`${dColor}-focus`], 'value', daisyuiFocusColor);
          }
        });
      }
      const variations = colors[color].variations;
      // color variations:
      if (variations) {
        colorVariations.forEach(variant => {
          const vName = `${variations}-${variant.postfix}`;
          if (colors[vName]) {
            Vue.set(colors[vName], 'value', variant.make(value));
          }
        })
      }
    },
    SET_THEME_LOGO(state, payload) {
      Vue.set(state.theme, 'logo', payload);
    },
    SET_THEME_USE_DEFAULT(state, payload) {
      Vue.set(state.theme, 'use_default', payload);
    },
  },

  actions: {
    fetchTenant: async ({ commit }, id) => {
      try {
        const { data } = await axios.get(`tenant/${id}`);
        commit("SET_TENANT_CONFIG", data);
        return data;
      } catch (error) {
        console.log(">>>> error", error);
      }
    },
    setTenant: async (context, payload) => {
      try {
        const { data } = await axios.put(`tenant`, payload);
        return data;
      } catch (error) {
        console.log(">>>> error", error);
      }
    },
  },
}
